.form {
  flex: 1 1 auto;
  margin: var(--spacing);
  margin-top: 0;
  overflow-y: scroll;
}

.form p {
  margin: var(--spacing) 0;
}

.field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: var(--spacing) 0;
  width: 100%;
}

.field span {
  flex: 0 0 auto;
  white-space: pre-wrap;
}

.field input {
  flex: 1 1 auto;
}

.field input.validated:valid {
  background: rgba(222 255 222 / 100%);
}

.field input.validated:invalid {
  background: rgba(222 127 127 / 100%);
}
