:root {
  --settings-font-size: 1em;
  --player-button-size: 8em;
  --player-icon-size: 4em;
  --edit-icon-size: 2em;
}

.rules {
  flex: 0 1 0;
  margin: var(--spacing);
  font-size: var(--settings-font-size);
}

.rules input[type="number"] {
  width: 6em;
}

.players {
  flex: 1 1 0;
  min-height: 0;
}

.players > div {
  height: calc(100% - 2 * var(--spacing));
}

.players button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  font-size: 1em; /* override browser defaults */
}

.icon {
  margin: var(--spacing);
  margin-left: calc(var(--spacing) / 2);
  height: var(--player-icon-size);
}

.edit-icon {
  margin: calc(var(--spacing) + (var(--player-icon-size) - var(--edit-icon-size)) / 4);
  margin-right: calc(var(--spacing) / 2 + (var(--player-icon-size) - var(--edit-icon-size)) / 4);
  height: var(--edit-icon-size);
}

.description {
  flex: 1 1 var(--player-button-size);
  margin: var(--spacing) var(--spacing) var(--spacing) 0;
  text-align: start;
  font-size: var(--settings-font-size);
}

.message {
  vertical-align: middle;
}

.message img {
  height: 0.75em;
}
