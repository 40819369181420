:root {
  /* Colors */
  --letterbox-color: rgba(0 0 0 / 100%);
  --app-background-color: rgba(239 239 239 / 100%);
  --font-color: rgba(0 0 0 / 100%);

  /* Sizes */
  --minimum-app-size: 300px;
  --heading-1-size: 2.5em;
  --heading-2-size: 2em;
  --heading-3-size: 1.75em;
  --heading-4-size: 1.5em;
  --heading-5-size: 1.25em;
  --heading-6-size: 1em;
  --primary-component-basis: 50%;
  --secondary-component-basis: 2em;
  --spacing: 1em;
  --rounding: 8px;

  /* Shadows */
  --shadow: 0 0 8px rgba(0 0 0 / 100%);

  /* Durations */
  --animation-duration: 400ms;
}

body {
  margin: 0;
  text-align: center;
  font-family: sans-serif;
  color: var(--font-color);
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--letterbox-color);
}

#portrait {
  position: relative;
  margin: auto;
  min-width: var(--minimum-app-size);
  min-height: var(--minimum-app-size);
  width: 100%;
  height: 100%;
  max-width: 62.5vh;
  background: var(--app-background-color);
  overflow: hidden;
  transform: scale(1);
}

#app {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: var(--heading-1-size);
}

h2 {
  font-size: var(--heading-2-size);
}

h3 {
  font-size: var(--heading-3-size);
}

h4 {
  font-size: var(--heading-4-size);
}

h5 {
  font-size: var(--heading-5-size);
}

h6 {
  font-size: var(--heading-6-size);
}

button,
svg {
  -webkit-tap-highlight-color: transparent;
}
