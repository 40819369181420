.toggle {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.toggle .switch-part {
  flex: 0 0 auto;
  margin: 1em;
}

.toggle .label-part {
  flex: 1 1 auto;
  text-align: start;
}
