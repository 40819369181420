:root {
  --board-instructions-margin: 6.25%;
  --board-background-color: rgba(127 199 127 / 100%);
  --board-marking-color: rgba(0 0 0 / 100%);
  --board-highlighted-marking-color: rgba(0 0 255 / 100%);
  --board-selection-color: rgba(0 127 0 / 100%);
  --board-move-color: rgba(0 127 0 / 25%);
  --board-hovered-move-color: rgba(0 127 0 / 50%);
}

.board {
  position: relative;
  flex: 0 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

/* For cross-browser compatability, the div around an SVG must have its width
   set, not just the SVG itself.  It is not clear which browser's behavior is
   correct, but see <https://bugzilla.mozilla.org/show_bug.cgi?id=1638937> for
   some discussion.  As a bonus, this rule also lets us sidestep the related but
   distinct bug <https://bugzilla.mozilla.org/show_bug.cgi?id=1270568>. */
.board > div:not(.instructions, .filler) {
  width: 100%;
}

.instructions {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: var(--board-instructions-margin);
  border: 0.5em dashed var(--font-color);
  width: calc(100% - 2 * var(--board-instructions-margin));
  height: calc(100% - 2 * var(--board-instructions-margin));
}

.instructions p {
  flex: 0 1 auto;
  margin: 0.5em var(--board-instructions-margin);
  vertical-align: middle;
}

.instructions img {
  height: 0.75em;
}

.filler {
  flex: 1 1 0;
}

svg {
  display: block;
  width: 100%;
  cursor: pointer;
}

.surface {
  stroke: none;
  fill: var(--board-background-color);
}

.file,
.rank {
  stroke: var(--board-marking-color);
}

.label {
  font-family: sans-serif;
}

.dot {
  stroke: var(--board-marking-color);
  fill: var(--board-marking-color);
}

.highlight {
  stroke: var(--board-highlighted-marking-color);
  fill: var(--board-highlighted-marking-color);
}

.label.highlight {
  fill: var(--board-marking-color);
}

.dot.highlight {
  stroke: var(--board-marking-color);
}

.target {
  stroke: var(--board-highlighted-marking-color);
  fill: rgba(0 0 0 / 0%);
}

.suggestion,
.arrowhead,
.analysis-indicator {
  stroke: var(--board-highlighted-marking-color);
  fill: var(--board-highlighted-marking-color);
}

.suggestion,
.analysis-indicator {
  opacity: 50%;
}

.square {
  stroke: rgba(0 0 0 / 0%);
  fill: rgba(0 0 0 / 0%);
}

.square.from {
  stroke: var(--board-selection-color);
}

.square.to {
  fill: var(--board-move-color);
}

.square.to:hover {
  fill: var(--board-hovered-move-color);
}

.piece,
.target,
.suggestion,
.arrowhead,
.analysis-indicator {
  pointer-events: none;
}
