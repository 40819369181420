:root {
  --modal-overlay-color: rgba(0 0 0 / 50%);
  --modal-size: 80%;
}

.overlay {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--modal-overlay-color);
}

.content {
  flex: 0 0 auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: var(--modal-size);
  max-height: var(--modal-size);
}
