nav {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin: var(--spacing);
  margin-top: 0;
}

nav > div:last-child {
  height: 25%;
}

.subpage-enter {
  opacity: 0%;
}

.subpage-enter.subpage-enter-active {
  opacity: 100%;
  transition: opacity var(--animation-duration);
}

.subpage-exit {
  opacity: 100%;
}

.subpage-exit.subpage-exit-active {
  opacity: 0%;
  transition: opacity var(--animation-duration);
}
