:root {
  --caption-flex-shrink: 2;
  --caption-margin: 2em;
}

.caption {
  flex: 1 var(--caption-flex-shrink) var(--secondary-component-basis);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: stretch;
  margin: 0 var(--caption-margin);
  min-height: 0;
}

.caption div {
  flex: 0 0 auto;
  margin: 0;
  max-height: 100%;
  overflow-y: auto;
}

.caption p {
  margin: 0;
  vertical-align: middle;
}

.caption img {
  height: 0.75em;
}
