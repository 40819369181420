:root {
  --button-bar-button-size: 4em;
  --button-bar-active-color: rgba(0 0 0 / 25%);
  --button-bar-disabled-opacity: 25%;
  --button-bar-image-size: 2em;
}

.button-bar {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: var(--spacing);
  border-radius: var(--rounding);
  box-shadow: var(--shadow);
  min-width: 50%;
  padding: 0;
  background: var(--app-background-color);
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

/* As of mid-2022, Blink- and Webkit-based browsers do not yet support the
   standard `scrollbar-width` property yet; see
   <https://caniuse.com/?search=scrollbar-width> for more information. */
.button-bar::-webkit-scrollbar {
  display: none;
}

.button-bar div {
  flex: 1 0 calc(100% / 6.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;
}

.button-bar button {
  border: none;
  border-radius: 0;
  padding: var(--spacing);
  height: 100%;
  min-width: 0;
  max-height: var(--button-bar-button-size);
  background: rgba(0 0 0 / 0%);
  text-align: center;
  scroll-snap-align: start;
}

.button-bar button:disabled {
  opacity: var(--button-bar-disabled-opacity);
}

.button-bar button.highlight:not(:disabled),
.button-bar button:active:not(:disabled) {
  background: var(--button-bar-active-color);
}

.button-bar button img {
  height: var(--button-bar-image-size);
}
