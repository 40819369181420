:root {
  --score-sheet-background-color: rgba(255 255 255 / 100%);
  --score-sheet-main-line-color: rgba(0 0 0 / 100%);
  --score-sheet-variation-color: rgba(127 127 127 / 100%);
  --score-sheet-variation-brace-thickness: 4px;
  --score-sheet-turn-spacing: 0.25em;
  --score-sheet-turn-number-width: 3em;
  --score-sheet-move-spacing: 0.25em;
  --score-sheet-move-border-size: 1px;
  --score-sheet-move-width: 8em;
  --score-sheet-active-move-color: rgba(0 127 0 / 25%);
  --score-sheet-hovered-active-move-color: rgba(0 127 0 / 50%);
}

.score {
  flex: 1 1 var(--primary-component-basis);
  box-sizing: border-box;
  margin: 0 var(--spacing) var(--spacing) var(--spacing);
  border-radius: var(--rounding);
  padding: var(--spacing) 0;
  background: var(--score-sheet-background-color);
  text-align: start;
  overflow: scroll;
}

.no-moves {
  text-align: center;
}

.variation {
  display: block;
  margin: var(--spacing) 0 var(--spacing) var(--spacing);
  border-left: var(--score-sheet-variation-brace-thickness) solid var(--score-sheet-variation-color);
  border-radius: var(--rounding);
  color: var(--score-sheet-variation-color);
}

.turn {
  display: block;
  margin: var(--score-sheet-turn-spacing);
  white-space: nowrap;
}

.turn-number {
  display: inline-block;
  width: var(--score-sheet-turn-number-width);
  color: var(--score-sheet-main-line-color);
  text-align: end;
}

.turn-number::after {
  content: ". "; /* stylelint-disable-line no-irregular-whitespace */
}

.variation .turn-number {
  color: var(--score-sheet-variation-color);
}

.move {
  display: inline-block;
  border: var(--score-sheet-move-border-size) solid var(--score-sheet-main-line-color);
  padding: var(--score-sheet-move-spacing);
  width: var(--score-sheet-move-width);
  color: var(--score-sheet-main-line-color);
  text-align: center;
}

.variation .move {
  border: var(--score-sheet-move-border-size) solid var(--score-sheet-variation-color);
  color: var(--score-sheet-variation-color);
}

.move + .move {
  border-left: none;
}

.move:not(:disabled) {
  cursor: pointer;
}

.move.active {
  background: var(--score-sheet-active-move-color);
  font-weight: bold;
}

.move:hover:not(:disabled),
.move.active:hover:not(:disabled) {
  background: var(--score-sheet-hovered-active-move-color);
}
