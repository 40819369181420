.editor {
  flex: 1 1 var(--primary-component-basis);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: stretch;
  box-sizing: border-box;
  margin: 0 var(--spacing) var(--spacing) var(--spacing);
}
