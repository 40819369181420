:root {
  --menu-button-basis: 4em;
  --menu-button-border-thickness: 2px;
  --menu-button-border-color: rgba(127 127 127 / 100%);
  --menu-button-active-color: rgba(0 0 0 25%);
  --menu-button-disabled-opacity: 50%;
  --menu-text-box-background-color: rgba(255 255 255 / 100%);
  --menu-text-box-padding: 0.5em;
  --main-menu-font-size: 1.5em;
  --main-menu-icon-size: 5em;
}

.menu {
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  align-items: stretch;
  margin: var(--spacing);
  box-sizing: border-box;
  border-radius: var(--rounding);
  box-shadow: var(--shadow);
  min-height: 0;
  background: var(--app-background-color);
  overflow: hidden;
}

.menu:not(.bottom-aligned) {
  justify-content: flex-start;
}

.menu.bottom-aligned {
  justify-content: flex-end;
}

div[role="dialog"] .menu {
  max-height: 100%;
}

.scrollable {
  max-height: 100%;
  overflow-y: auto;
}

.list {
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  align-items: stretch;
}

.menu button {
  flex: 0 0 var(--menu-button-basis);
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  padding: var(--spacing);
  background: rgba(0 0 0 / 0%);
  text-align: center;
}

.menu button.decorative {
  font-weight: bold;
  color: var(--font-color);
}

.menu button:disabled:not(.decorative) {
  opacity: var(--menu-button-disabled-opacity);
}

.menu button:active:not(:disabled) {
  background: var(--menu-button-active-color);
}

/* stylelint-disable no-descending-specificity -- intentionally keep background and border code separated */

.menu button:not(:first-of-type) {
  margin-top: calc(-0.5 * var(--menu-button-border-thickness));
  border-top: calc(0.5 * var(--menu-button-border-thickness)) solid var(--menu-button-border-color);
}

.menu.bottom-aligned button:first-of-type {
  border-top: var(--menu-button-border-thickness) solid var(--menu-button-border-color);
}

.menu button:not(:last-of-type) {
  margin-bottom: calc(-0.5 * var(--menu-button-border-thickness));
  border-bottom: calc(0.5 * var(--menu-button-border-thickness)) solid var(--menu-button-border-color);
}

.menu:not(.bottom-aligned) button:last-of-type {
  border-bottom: var(--menu-button-border-thickness) solid var(--menu-button-border-color);
}

.menu:not(.bottom-aligned) button:first-of-type {
  border-top-left-radius: var(--rounding);
  border-top-right-radius: var(--rounding);
}

.menu.bottom-aligned button:last-of-type {
  border-bottom-left-radius: var(--rounding);
  border-bottom-right-radius: var(--rounding);
}

/* stylelint-enable no-descending-specificity */

.main-menu-content {
  font-size: var(--main-menu-font-size);
  padding: var(--spacing);
}

.main-menu-content img {
  display: block;
  margin: 0 auto var(--spacing) auto;
  max-width: var(--main-menu-icon-size);
}

.menu input[type="text"] {
  flex: 0 0 auto;
  margin: 0;
  border-top: var(--menu-button-border-thickness) solid var(--menu-button-border-color);
  border-right: none;
  border-bottom: var(--menu-button-border-thickness) solid var(--menu-button-border-color);
  border-left: none;
  border-radius: 0;
  padding: var(--menu-text-box-padding);
  background: var(--menu-text-box-background-color);
}
