:root {
  --heading-flex-grow: 0.1;
  --heading-horizontal-margin: 2em;
}

.heading {
  flex: var(--heading-flex-grow) 0 var(--secondary-component-basis);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: stretch;
  margin: var(--spacing) var(--heading-horizontal-margin);
}
