.page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: var(--minimum-app-size);
  min-height: var(--minimum-app-size);
  background: var(--app-background-color);
}
